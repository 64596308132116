import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Dashboard() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(true)
  const [dataready, setdataready] = useState(false)

  /// use profits to display earnings too and also
  const [profits, setprofits] = useState(0)

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  function cleanDate(d) {
    var date = new Date(d);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    console.log("Date: " + date.getDate() +
      "/" + (months[date.getMonth() + 1]) +
      "/" + date.getFullYear() +
      " " + date.getHours() +
      ":" + date.getMinutes() +
      ":" + date.getSeconds());
    return `${date.getDate()} ${(months[date.getMonth()])} ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
      planduecheck(userdetails)
      console.log(userdetails.totalearnings)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false)

        } else {
          setloggedin(false);
          setOpen(!open);
          navigate("/");
        }
      });
    }
  }, []);


  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setloggedin(true)
          setOpen(!open);
          planduecheck(doc.data())
          setdataready(true)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };


  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const planduecheck = (info) => {
    const d1 = new Date();
    // Loop through currentSubscriptionArray
    if (info.currentSubscriptionArray.legnth != 0) {

      // && info.currentSubscription.dueDate !==null
      var Profits = 0
      info.currentSubscriptionArray.map((data) => {
        console.log(info.email);
        if (d1.getTime() >= data.dueDate) {
          const newBal =
            parseInt(info.balance) +
            parseInt(data.dueAmount);

          let FilteredArray = info.currentSubscriptionArray.filter((a) => { if (a.dueDate != data.dueDate) { return a } });
          console.log(FilteredArray)
          updateUserBalanceandSub(newBal, data.dueAmount, FilteredArray);
          console.log(data.dueDate);
        } else if (d1.getTime() < data.dueDate) {
          var today = new Date();
          var total = data.dueDate - data.dateSubscribed;
          var progress = today - data.dateSubscribed;

          console.log(Math.round(progress / total * 100) + "%");
          // data.dueAmount-data.amount
          const currentprofit = (((progress / total * 100) * (data.dueAmount - data.amount)) / 100) + Profits
          console.log(currentprofit)
          Profits = currentprofit

          console.log(data.dueDate - d1.getTime());
          const planprogress =
            ((data.dueDate - d1.getTime()) /
              data.dueDate) *
            100;
          console.log(planprogress);
          let date = new Date(data.dueDate);
          console.log(date.toString());
        }
      })

      console.log(Profits)
      // intrestprogress(Profits)
      setprofits(Profits)
    } else {
      console.log(info.email);
    }
  };

  const updateUserBalanceandSub = (bal, addedbal, newarray) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const balanceToUse = parseFloat(userdetails.balance) + parseFloat(bal)
    const increment = firebase.firestore.FieldValue.increment(parseInt(addedbal));
    washingtonRef
      .update({
        balance: bal,
        currentSubscription: null,
        currentSubscriptionArray: newarray,
        totalearnings: increment,
      })
      .then(function () {
        console.log("balance successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };


  const intrestprogress = (bal) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = db.collection("users").doc(userids);
    const increment = firebase.firestore.FieldValue.increment(parseInt(bal));
    washingtonRef
      .update({
        totalearning: 0,
      })
      .then(function () {
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata(userids);
  };



  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
      <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
      <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
      <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
      <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Loading wrapper start */}
      <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div>
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          {/* Content wrapper scroll start */}
          <div className="content-wrapper-scroll">
            {/* Content wrapper start */}
            <div className="content-wrapper">
              {/* Row start */}
              <div className="row gutters">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-shopping-bag1" />
                    </div>
                    <div className="sale-details">
                      <h2>${parseFloat(userdetails.balance).toLocaleString()}</h2>
                      <p>Current Balance</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine1" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-shopping-bag1" />
                    </div>
                    <div className="sale-details">
                      <h2>${parseFloat(userdetails.Totaldeposit).toLocaleString()}</h2>
                      <p>Total Deposits</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine2" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-check-circle" />
                    </div>
                    <div className="sale-details">

                      <h2>${parseFloat(userdetails.Totalwithdrawal).toLocaleString()}</h2>
                      <p>Total Withdrawals</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine3" />
                    </div>
                  </div>
                </div>


                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-check-circle" />
                    </div>
                    <div className="sale-details">
                      <h2>${parseFloat(userdetails.lockedbalance).toLocaleString()}</h2>

                      <p>Bonus</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine3" />
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-check-circle" />
                    </div>
                    <div className="sale-details">
                      <h2>${parseFloat(userdetails.totalprofit).toLocaleString()}</h2>
                      <p>Total Profit</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine3" />
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-check-circle" />
                    </div>
                    <div className="sale-details">
                      <h2>${parseFloat(userdetails.referralearn).toLocaleString()}</h2>
                      <p>Referral Bonus</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine3" />
                    </div>
                  </div>
                </div>
              </div>
              {/* Row end */}
              {/* Row start */}
              <div className="row gutters">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                  <div className="stats-tile">
                    <div className="sale-icon">
                      <i className="icon-shopping-bag1" />
                    </div>
                    <div className="sale-details">
                      <h2>${Math.round(profits * 10000) / 10000}</h2>
                      <p>Active Deposit</p>
                    </div>
                    <div className="sale-graph">
                      <div id="sparklineLine1" />
                    </div>
                  </div>
                </div>
                {/* Row end */}
                {/* Row start */}






                <div className="row gutters">
                  {/* <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">User Profile</div>
                        <div className="graph-day-selection" role="group">
                          <a href="?a=deposit" className="btn active">Deposit</a>
                        </div>
                      </div>
                      <div className="card-body">
                        <figure className="user-card">
                          <figcaption>
                            <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="{userdetails.fullname}" className="profile" />
                            <h5>{userdetails.fullname}</h5>
                            <ul className="list-group">
                              <li className="list-group-item"><span>Email: </span>{userdetails.email}</li>
                              <li className="list-group-item"><span>Full Name: </span>{userdetails.fullname}</li>
                              <li className="list-group-item"><span>Sponsor: </span></li>
                              <li className="list-group-item"><span>User Total Balance: </span>${userdetails.balance}</li>
                            </ul>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Dashboard</div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table products-table">
                            <tbody>
                              <tr>
                                <td align="left">Total Earning</td>
                                <td align="right">${parseFloat(userdetails.totalearnings).toLocaleString()}  </td>

                              </tr>
                              <tr>
                                <td align="left">Active Deposit</td>
                                <td align="right">${parseFloat(userdetails.Totaldeposit).toLocaleString()}  </td>
                              </tr>
                              <tr>
                                <td align="left">Last Access</td>
                                <td align="right">n/a</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {
                          !loading && (
                            <div className="card-item">
                              <h5 className="mb-1 text-bold">My Referral Link</h5>
                              <p>Click on the blue button to copy your referral link</p>
                              {/* <input type="text" defaultValue={`https://acorn-mart.com/a-signup.html?referralid=${f.auth().currentUser.uid} `} id="myInput" /> */}
                              <input type="text" defaultValue={`https://www.acorn-mart.com/a-signup.html?referralid=${f.auth().currentUser.uid.substr(1, 5)} `} id="myInput" />
                              <button style={{ backgroundColor: '#008CBA' }} onClick={() => { navigator.clipboard.writeText(`https://www.acorn-mart.com/a-signup.html?referralid=${f.auth().currentUser.uid.substr(1, 5)}`); }}><h5>Copy</h5></button>
                              <div className="col-sm-12 col-lg-12 col-xl-12 mb-4">
                                <div style={{ height: '560px', backgroundColor: '#FFFFFF', overflow: 'hidden', boxSizing: 'border-box', border: '1px solid #56667F', borderRadius: '4px', textAlign: 'right', lineHeight: '14px', fontSize: '12px', fontFeatureSettings: 'normal', textSizeAdjust: '100%', boxShadow: 'inset 0 -20px 0 0 #56667F', padding: '0px', margin: '0px', width: '100%' }}><div style={{ height: '540px', padding: '0px', margin: '0px', width: '100%' }}><iframe src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505" width="100%" height="536px" scrolling="auto" marginWidth={0} marginHeight={0} frameBorder={0} border={0} style={{ border: 0, margin: 0, padding: 0, lineHeight: '14px' }} /></div><div style={{ color: '#FFFFFF', lineHeight: '14px', fontWeight: 400, fontSize: '11px', boxSizing: 'border-box', padding: '2px 6px', width: '100%', fontFamily: 'Verdana, Tahoma, Arial, sans-serif' }}><a href="https://coinlib.io" target="_blank" style={{ fontWeight: 500, color: '#FFFFFF', textDecoration: 'none', fontSize: '11px' }}>Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>
                              </div>
                            </div>
                          )
                        }



                      </div>
                    </div>
                  </div>
                </div>

                {
                  dataready && (
                    userdetails.currentSubscriptionArray.map((obj, i) => (
                      <>
                        <div className="col-sm-12 col-lg-12 col-md-12 mt-3 mb-2">
                          <div className="card l-bg-orange-dark bgcolor">
                            <div className="card-statistic-3 p-4">
                              <div className="card-icon card-icon-large" />
                              <div className="mb-3">
                                <h5 className="card-title mb-0 text-blue sale-details"> Active Plan :{obj.currentPlan}</h5>
                              </div>
                              <div className="mb-3">
                                <h5 className="card-title mb-0 text-blue">Start Date :{new Date(obj.dateSubscribed).toLocaleString()}</h5>
                              </div>
                              <div className="mb-3">
                                <h5 className="card-title mb-0 text-blue">End Date :{new Date(obj.dueDate).toLocaleString()}</h5>
                              </div>
                              <div className="mb-3">
                                <h5 className="card-title mb-0 text-blue">Amount Invested :${obj.amount}</h5>
                              </div>
                              <div className="mb-3">
                                <h5 className="card-title mb-0 text-blue">Profit + Amount Invested :${obj.dueAmount}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>


                    ))

                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
      {/* App footer start */}
      <div className="app-footer">© Acorn-mart</div>
      {/* App footer end */}
      {/* Content wrapper scroll end */}
      {/* *************
            ************ Main container end *************
        ************* */}
      {/* Page wrapper end */}
      {/* *************
        ************ Required JavaScript Files *************
    ************* */}
      {/* Required jQuery first, then Bootstrap Bundle JS */}
      {/* *************
        ************ Vendor Js Files *************
    ************* */}
      {/* Megamenu JS */}
      {/* Slimscroll JS */}
      {/* Search Filter JS */}
      {/* Apex Charts */}
      {/* Circleful Charts */}
      {/* Main Js Required */}
      {/*Start of Tawk.to Script*/}
      {/*End of Tawk.to Script*/}
    </div>
  )
}

export default Dashboard