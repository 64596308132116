import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes, Switch } from "react-router-dom";
import { GlobalState } from "./Globalstate";
import AppContainer from "./AppContainer";
import Dashboard from "./Pages/Dashboard";
import Deposit from "./Pages/Deposit";
import DepositPayment from "./Pages/DepositPayment";
import ManagedAccount from "./Pages/ManagedAccount";
import Myplans from "./Pages/Myplans";
import Profile from "./Pages/Profile";
import ProfitsHistory from "./Pages/ProfitsHistory";
import Refferals from "./Pages/Refferals";
import TradingPlans from "./Pages/TradingPlans";
import Transactions from "./Pages/Transactions";
import TransferFunds from "./Pages/TransferFunds";
import Withdrawal from "./Pages/Withdrawal";
import DepositHistory from "./Pages/DepositHistory";
import DepositList from "./Pages/DepositList";
import WithdrawalHistory from "./Pages/WithdrawalHistory";
import Security from "./Pages/Security";
import WithdrawalComplete from "./Pages/WithdrawalComplete";
import RealEstate from "./Pages/RealEstate";
import "./App.css"


function App() {

  return (
    <GlobalState>
      {/* <ToastContainer className="foo" /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<AppContainer />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/DepositHistory" element={<DepositHistory />} />
          <Route path="/DepositList" element={<DepositList />} />
          <Route path="/ManagedAccount" element={<ManagedAccount/>} />
          <Route path="/realestate" element={<RealEstate/>} />
          <Route path="/Myplans" element={<Myplans/>} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/Security" element={<Security/>} />
          <Route path="/ProfitsHistory" element={<ProfitsHistory />} />
          <Route path="/Refferals" element={<Refferals />} />
          <Route path="/TradingPlans" element={<TradingPlans/>} />
          <Route path="/Transactions" element={<Transactions/>} />
          <Route path="/TransferFunds" element={<TransferFunds/>} />
          <Route path="/Withdrawal" element={<Withdrawal/>} />
          <Route path="/WithdrawalHistory" element={<WithdrawalHistory/>} />
        </Routes>
      </BrowserRouter>
    </GlobalState>
  );
}

export default App;
