import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyAUVZUuP1SjiHmWzz9u-G7i8HPJBFu3ogQ",
  authDomain: "acorn-mart.firebaseapp.com",
  databaseURL: "https://acorn-mart-default-rtdb.firebaseio.com",
  projectId: "acorn-mart",
  storageBucket: "acorn-mart.appspot.com",
  messagingSenderId: "340363378113",
  appId: "1:340363378113:web:11db46b49c316658f70340"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()