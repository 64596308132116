import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function RealEstate() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          setdataready(true)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const images = [
    'img/realestate/1.jpeg',
    'img/realestate/2.jpeg',
    'img/realestate/3.jpeg',
    'img/realestate/4.jpeg',
    'img/realestate/5.jpeg',
    'img/realestate/6.jpeg',
    'img/realestate/7.jpeg',
    'img/realestate/8.jpeg',
    'img/realestate/9.jpeg',
    'img/realestate/10.jpeg'
  ];

  const imageStyle = {
    width: '100%',
    maxWidth: '200px',
    borderRadius: '10px',
    margin: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  };
  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/*--------------- www.hyipcustomize.com ---------*/}
      <meta name="designer" href="https://hyipcustomize.com/" />
      {/* Title */}
      <title>Acorn-mart | Dashboard</title>
      {/* *************
        ************ Common Css Files *************
    ************ */}
      {/* Bootstrap css */}
      <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
      {/* Icomoon Font Icons css */}
      <link rel="stylesheet" href="dashboard/fonts/style.css" />
      {/* Main css */}
      <link rel="stylesheet" href="dashboard/css/main.css" />
      {/* *************
        ************ Vendor Css Files *************
    ************ */}
      {/* Mega Menu */}
      <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
      {/* Search Filter JS */}
      <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
      <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
      {/* Loading wrapper start */}
      <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div>
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          {/* Content wrapper scroll start */}
          <div className="content-wrapper-scroll">
            {/* Content wrapper start */}
            <div className="content-wrapper">
              {/* Row start */}
              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <header style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#aa6c39', color: '#fff', padding: '2px 20px', borderRadius: '10px 10px 0 0' }}>
      <h3 style={{ fontSize: '16px', color: '#333' }}>Profits: {userdetails.realestateprofit}</h3>
      <h3 style={{ fontSize: '16px', color: '#333' }}>Bonus: {userdetails.realestatebonus}</h3>
      </header>
      <section style={{ padding: '20px' }}>
        <h1 style={{ color: '#aa6c39' }}>Commercial Real Estate</h1>
        <p>
          Commercial real estate is a category of real property that is primarily commercial in nature or purpose; and
          property types in commercial real estate includes office, industrial, retail, and hospitality.
        </p>
        <img src={images[0]} alt="Commercial Real Estate" style={{ ...imageStyle, float: 'left', marginRight: '20px' }} />
        <h2 style={{ color: '#aa6c39' }}>Real Estate Arbitrage</h2>
        <p>
          Real estate Arbitrage describes the act of buying a secured property in one market and simultaneously selling
          it in another market at a higher price, thereby enabling investors to profit from the temporary difference in
          cost per share.
        </p>
        <p>
          Acorn-mart deals practically on Airbnb Rental arbitrage which is an age-old business model that transcends
          almost every industry globally. In theory, it is very simple, buy ‘Carcass home’, turn it into your choiced
          ‘investment arbitrage property, then sell that property or lease at a higher price. Because of the simplicity
          of the business model, Our community has widely adopted it. In fact, you probably have stayed in an Airbnb
          that operated as a rental arbitrage unit, without even knowing it!
        </p>
        <img src={images[1]} alt="Real Estate Arbitrage" style={{ ...imageStyle, float: 'right', marginLeft: '20px' }} />
        <h2 style={{ color: '#aa6c39' }}>Real Estate Overview</h2>
        <p>
          Real estate encompasses the land, plus any permanent man-made additions, such as houses and other buildings.
          Any additions or changes to the land that affects the property's value are called an improvement.
        </p>
        <img src={images[2]} alt="Real Estate Overview" style={{ ...imageStyle, float: 'left', marginRight: '20px' }} />
        <p>
          Once a land is improved, the total capital and labor used to build the improvement represent a sizable fixed
          investment. Though a building can be razed, improvements like drainage, electricity, water and sewer systems
          tend to be permanent.
        </p>
        <p>
          Real property includes the land and additions to the land plus the rights inherent to its ownership and usage.
        </p>
        <img src={images[3]} alt="Residential Real Estate" style={{ ...imageStyle, float: 'right', marginLeft: '20px' }} />
        <h2 style={{ color: '#aa6c39' }}>Residential Real Estate</h2>
        <p>
          Residential real estate includes any property used for residential purposes. Examples include single-family
          homes, condos, cooperatives, duplexes, townhouses, and multifamily residences.
        </p>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
          {images.slice(4).map((src, index) => (
            <img key={index} src={src} alt={`Real Estate ${index + 1}`} style={imageStyle} />
          ))}
        </div>
      </section>
    </div>
              </div></div>
          {/* App footer start */}
          <div className="app-footer">© Acorn-mart</div>
          {/* App footer end */}
        </div>
        {/* Content wrapper scroll end */}
      </div>
      {/* *************
            ************ Main container end *************
        ************* */}
      {/* Page wrapper end */}
      {/* *************
        ************ Required JavaScript Files *************
    ************* */}
      {/* Required jQuery first, then Bootstrap Bundle JS */}
      {/* *************
        ************ Vendor Js Files *************
    ************* */}
      {/* Megamenu JS */}
      {/* Slimscroll JS */}
      {/* Search Filter JS */}
      {/* Apex Charts */}
      {/* Circleful Charts */}
      {/* Main Js Required */}
      {/*Start of Tawk.to Script*/}
      {/*End of Tawk.to Script*/}
    </div>
  )
}

export default RealEstate