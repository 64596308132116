import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";


function Withdrawal() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)
  const [coin, setcoin] = useState("BITCOIN")
  const [wallet, setwallet] = useState("")
  const [Amount, setAmount] = useState("")



  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const SetcoinTouse = (no) => {

    if (no == 2) {
      setcoin("Tether")
    }
    if (no == "6") {
      setcoin("Ethereum")
    }
    if (no == "7") {
      setcoin("Bitcoin")
    }
    setstep(2)
  }
  const confirmWitdrawal = async () => {

    if (Amount != "") {
      console.log(Amount)
      console.log(userdetails.balance)
      if (parseFloat(userdetails.balance) < parseFloat(Amount)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "balance not enough",
          footer: '<a href="">Why do I have this issue?</a>'
        })
      } else {
        await updatewithdrawalhistory()
      }

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "no Amount selected",
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  }


  const updatewithdrawalhistory = async () => {

    if (coin == "" || Amount == "" || wallet == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Select wallet, Coin , and Amount to procecced withdrawal",
        footer: '<a href="">Why do I have this issue?</a>'
      })
      return 0
    }
    // console.log(cointype)
    setloading(true)
    const newBal = parseFloat(userdetails.balance); /*- parseFloat(Amount);*/
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    // const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      // Totalwithdrawal: increment,
      Withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",

      }),
    });
    await sendAdminMaiil()
    await updateUserBalance()
    await CreatWithdrawalObj()
  await sendAdminMaiil()

  };

  const sendAdminMaiil = async () => {
    var templateParams = {
      message:
        `${userdetails.fullname} made a withdrawal to his account `,
    };
    await emailjs
      .send(
        "service_5jy1gr4","template_zntnjos",
        templateParams,
        "HNlgG_D2lYE6E-7Q3"
      )
      .then(
        function (response) {
          // setloading(false)
        },
        function (err) {
          // setloading(false)
          alert("FAILED...", err);
        }
      );
  }
  const updateUserBalance = async (bal) => {
    const newEarings = parseFloat(userdetails.balance) - parseFloat(Amount);
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    var washingtonRef = await db.collection("users").doc(userids);
    washingtonRef
      .update({
        balance: newEarings,
      })
      .then(function () {
        console.log("Document successfully up2dated!");
        // alert("withdrawal was successful we will get back to you");
        Swal.fire({
          icon: 'success',
          title: 'withdrawal was successful we will get back to you!',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.replace("/dashboard");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    // fetchuserdata();
  };
  const CreatWithdrawalObj = async () => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const d1 = new Date();
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: {
        amt: Amount,
        date: d1.getTime(),
        mode: "withdrawal",
        wallet: wallet,
        coin: coin,
        status: "pending",
      }
    };
    const userdetail1 = await db
      .collection("withdrawals")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/*--------------- www.hyipcustomize.com ---------*/}
      <meta name="designer" href="https://hyipcustomize.com/" />
      {/* Title */}
      <title>Acorn-mart | Dashboard</title>
      {/* *************
        ************ Common Css Files *************
    ************ */}
      {/* Bootstrap css */}
      <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
      {/* Icomoon Font Icons css */}
      <link rel="stylesheet" href="dashboard/fonts/style.css" />
      {/* Main css */}
      <link rel="stylesheet" href="dashboard/css/main.css" />
      {/* *************
        ************ Vendor Css Files *************
    ************ */}
      {/* Mega Menu */}
      <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
      {/* Search Filter JS */}
      <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
      <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
      {/* Loading wrapper start */}
      <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div>
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          <form method="post"><input type="hidden" name="form_id" defaultValue={16862971357717} /><input type="hidden" name="form_token" defaultValue="3adc5b0ba9c7ec4a1e9267ae1b283434" />
            <input type="hidden" name="a" defaultValue="withdraw" />
            <input type="hidden" name="action" defaultValue="preview" />
            <input type="hidden" name="say" defaultValue />
            <div className="col-sm-12">
              <div className="card">
                <h5 className="card-header bg-primary text-white">Withdraw</h5>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody><tr>
                        <td>Account Balance:</td>
                        <td>$<b>0.00</b></td>
                      </tr>
                        <tr>
                          <td>Pending Withdrawals: </td>
                          <td>$<b /></td>
                        </tr>
                      </tbody></table>
                  </div></div></div></div>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody><tr>
                        <th />
                        <th scope="col">Processing</th>
                        <th scope="col">Available</th>
                        <th scope="col">Pending</th>
                        {/* <th scope="col">Account</th> */}
                      </tr>
                        <tr>
                          <td />
                          <td><img src="images/1006.gif" width={44} height={17} align="absmiddle" /> Bitcoin</td>
                          <td><b style={{ color: 'green' }}>$0.00</b></td>
                          <td><b style={{ color: 'red' }}>$0.00</b></td>
                          {/* <td>Address: 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</td> */}
                        </tr>
                        <tr>
                          <td />
                          <td><img src="images/1007.gif" width={44} height={17} align="absmiddle" /> Tether USDT TRC20</td>
                          <td><b style={{ color: 'green' }}>$0.00</b></td>
                          <td><b style={{ color: 'red' }}>$0.00</b></td>
                          {/* <td>Account ID: 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</td> */}
                        </tr>
                      </tbody></table>
                    <br /><br />
                    {/* <div className="alert alert-warning m-b-lg" role="alert">
                    You have no funds to withdraw.
                  </div> */}
                  </div></div></div></div></form>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            {/* Card start */}
            <div className="card">
              <div className="card-header">
                <div className="card-title">Make A Withdrawal</div>
              </div>
              <div className="card-body">
                {/* Row start */}
                <div className="row gutters">
                  <div className="col-md-12">
                    {/* Card start */}
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title">Fill The Form</div>
                      </div>
                      <div className="card-body">
                        {/* Row start */}
                        <div className="row gutters">
                          <form method="post" name="spendform"><input type="hidden" name="form_id" defaultValue={16862959794119} /><input type="hidden" name="form_token" defaultValue="08bae86c7de245e360aca85c0268335b" />
                            <input type="hidden" name="a" defaultValue="deposit" />
                            <div className="col-md-12">
                              {/* Field wrapper start */}
                              <div className="field-wrapper">
                                <input className="form-control" type="text" defaultValue={userdetails.balance} disabled />
                                <div className="field-placeholder">Your Account Balance</div>
                              </div>
                              {/* Field wrapper end */}
                            </div>
                            
                            <div className="col-md-12">
                              {/* Field wrapper start */}
                              <div className="field-wrapper">
                                <select className="form-select" id="formSelect" onChange={(e)=>{SetcoinTouse(e.target.value)}} name="type">
                                  <option value="6" checked>Bitcoin</option>
                                  <option value="7">Tether USDT TRC20</option>
                                </select>
                                <div className="field-placeholder">Select Payment Method</div>
                              </div>
                              {/* Field wrapper end */}
                            </div>
                            <div className="col-md-12">
                              {/* Field wrapper start */}
                              <div className="field-wrapper">
                                <input className="form-control" type="text" name="address" onChange={(e)=>{setwallet(e.target.value)}} />
                                <div className="field-placeholder">Enter Wallaet Address</div>
                              </div>
                              {/* Field wrapper end */}
                            </div>
                            <div className="col-md-12">
                              {/* Field wrapper start */}
                              <div className="field-wrapper">
                                <input className="form-control" type="text" name="amount" defaultValue={100.00} onChange={(e)=>{setAmount(e.target.value)}}/>
                                <div className="field-placeholder">Enter Amount</div>
                              </div>
                              {/* Field wrapper end */}
                            </div>
                            <div className="col-md-12">
                              <button className="btn btn-primary" type="button" onClick={confirmWitdrawal}>withdrawal</button>
                            </div>
                          </form>
                        </div>
                        {/* Row end */}
                      </div>
                    </div>
                    {/* Card end */}
                  </div>
                </div>
                {/* Row end */}
              </div>
              {/* Content wrapper end */}
              {/* App footer start */}
              <div className="app-footer">© Acorn-mart</div>
              {/* App footer end */}
            </div>
            {/* Content wrapper scroll end */}
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
      {/* App footer start */}
      <div className="app-footer">© Acorn-mart</div>
      {/* App footer end */}
      {/* Content wrapper scroll end */}
      {/* *************
            ************ Main container end *************
        ************* */}
      {/* Page wrapper end */}
      {/* *************
        ************ Required JavaScript Files *************
    ************* */}
      {/* Required jQuery first, then Bootstrap Bundle JS */}
      {/* *************
        ************ Vendor Js Files *************
    ************* */}
      {/* Megamenu JS */}
      {/* Slimscroll JS */}
      {/* Search Filter JS */}
      {/* Apex Charts */}
      {/* Circleful Charts */}
      {/* Main Js Required */}
      {/*Start of Tawk.to Script*/}
      {/*End of Tawk.to Script*/}
    </div>
  )
}

export default Withdrawal