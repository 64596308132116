import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function ProfitsHistory() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)


  useEffect(() => {
      if (loggedin) {
          console.log(userdetails);
          console.log(userdetails.email);
          setloading(false)
      } else {
          f.auth().onAuthStateChanged(function (user) {
              if (user) {
                  var userid = f.auth().currentUser;
                  var userids = userid.uid;
                  fetchuserdata(userids);
                  setloggedin(true);
              } else {
                  setloggedin(false);
                  setloading(false)
                  history("/");
              }
          });
      }
  }, []);

  const fetchuserdata = async (userid) => {
      var docRef = db.collection("users").doc(userid);
      const fetching = await docRef
          .get()
          .then(function (doc) {
              if (doc.exists) {
                  setdetails(doc.data());
                  setloading(false)
                  setdataready(true)
              } else {
                  console.log("No such document!");
                  setloading(false)
              }
          })
          .catch(function (error) {
              console.log("Error getting document:", error);
          });
  };

  const setdetails = (data) => {
      dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
      dispatch({ type: "setloggedin", snippet: data });
  };
  return (
    <div>
        <Helmet>
      
      <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
    {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
    <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
    </Helmet>
    {/* Required meta tags */}
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    {/*--------------- www.hyipcustomize.com ---------*/}
    <meta name="designer" href="https://hyipcustomize.com/" />
    {/* Title */}
    <title>Acorn-mart | Dashboard</title>
    {/* *************
        ************ Common Css Files *************
    ************ */}
    {/* Bootstrap css */}
    <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
    {/* Icomoon Font Icons css */}
    <link rel="stylesheet" href="dashboard/fonts/style.css" />
    {/* Main css */}
    <link rel="stylesheet" href="dashboard/css/main.css" />
    {/* *************
        ************ Vendor Css Files *************
    ************ */}
    {/* Mega Menu */}
    <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
    {/* Search Filter JS */}
    <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
    <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
    {/* Loading wrapper start */}
    <div id="loading-wrapper">
      <div className="spinner-border" />
      Loading...
    </div>
    {/* Loading wrapper end */}
    {/* Page wrapper start */}
    <div className="page-wrapper">
      {/* Sidebar wrapper start */}
<Nav/>
      {/* Sidebar wrapper end */}
      {/* *************
            ************ Main container start *************
        ************* */}
      <div className="main-container">
        {/* Page header starts */}
        <div className="page-header">
          {/* Row start */}
          <div className="row gutters">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
              {/* Search container start */}
              <div className="search-container">
                {/* Toggle sidebar start */}
                <div className="toggle-sidebar" id="toggle-sidebar">
                  <i className="icon-menu" />
                </div>
                {/* Toggle sidebar end */}
              </div>
              {/* Search container end */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
              {/* Header actions start */}
              <ul className="header-actions">
                <li className="dropdown">
                  <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                    Hi {userdetails.fullname} &nbsp;
                    <span className="avatar">
                      <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                      <span className="status busy" />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                    <div className="header-profile-actions">
                      <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                      <a href="?a=security"><i className="icon-warning" />Security</a>
                      <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                    </div>
                  </div>
                </li>
              </ul>
              {/* Header actions end */}
            </div>
          </div>
          {/* Row end */}                    
        </div>
        {/* Page header ends */}
        {/* Content wrapper scroll start */}
        <div className="content-wrapper-scroll">
          {/* Content wrapper start */}
          <div className="content-wrapper"> 
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {/* Card start */}
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Earnings</div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <form method="post" name="opts"><input type="hidden" name="form_id" defaultValue={16862972773665} /><input type="hidden" name="form_token" defaultValue="77ac95752cc4b6f48be7e8e9bd43106c" />
                          <input type="hidden" name="a" defaultValue="history" />
                          <input type="hidden" name="page" defaultValue={1} />
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <select name="type" className="form-control" onchange="document.opts.submit();">
                                    <option value>All transactions</option>
                                    <option value="deposit">Deposit</option>
                                    <option value="withdrawal">Withdrawal</option>
                                    <option value="earning">Earning</option>
                                    <option value="commissions">Referral commission</option>
                                  </select>
                                </div>
                                <div className="col-md-1">
                                  <label>From:</label>
                                </div>
                                <div className="col-md-2">
                                  <select name="month_from" className="form-control">
                                    <option value={1}>Jan
                                    </option><option value={2}>Feb
                                    </option><option value={3}>Mar
                                    </option><option value={4}>Apr
                                    </option><option value={5}>May
                                    </option><option value={6} selected>Jun
                                    </option><option value={7}>Jul
                                    </option><option value={8}>Aug
                                    </option><option value={9}>Sep
                                    </option><option value={10}>Oct
                                    </option><option value={11}>Nov
                                    </option><option value={12}>Dec
                                    </option></select> &nbsp;
                                </div>
                                <div className="col-md-2">
                                  <select name="day_from" className="form-control">
                                    <option value={1}>1
                                    </option><option value={2}>2
                                    </option><option value={3}>3
                                    </option><option value={4}>4
                                    </option><option value={5}>5
                                    </option><option value={6}>6
                                    </option><option value={7} selected>7
                                    </option><option value={8}>8
                                    </option><option value={9}>9
                                    </option><option value={10}>10
                                    </option><option value={11}>11
                                    </option><option value={12}>12
                                    </option><option value={13}>13
                                    </option><option value={14}>14
                                    </option><option value={15}>15
                                    </option><option value={16}>16
                                    </option><option value={17}>17
                                    </option><option value={18}>18
                                    </option><option value={19}>19
                                    </option><option value={20}>20
                                    </option><option value={21}>21
                                    </option><option value={22}>22
                                    </option><option value={23}>23
                                    </option><option value={24}>24
                                    </option><option value={25}>25
                                    </option><option value={26}>26
                                    </option><option value={27}>27
                                    </option><option value={28}>28
                                    </option><option value={29}>29
                                    </option><option value={30}>30
                                    </option><option value={31}>31
                                    </option></select> &nbsp;
                                </div>
                                <div className="col-md-2">
                                  <select name="year_from" className="form-control">
                                    <option value={2015}>2015
                                    </option><option value={2016}>2016
                                    </option><option value={2017}>2017
                                    </option><option value={2018}>2018
                                    </option><option value={2019}>2019
                                    </option><option value={2020}>2020
                                    </option><option value={2021}>2021
                                    </option><option value={2022}>2022
                                    </option><option value={2023} selected>2023
                                    </option></select>
                                </div>
                              </div> 
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <br /><img src="images/q.gif" width={1} height={4} /><br />
                                  <select name="ec" className="form-control">
                                    <option value={-1}>All eCurrencies</option>
                                    <option value={1006}>Bitcoin</option>
                                    <option value={1007}>Tether USDT TRC20</option>
                                  </select> </div>
                                <div className="col-md-1">
                                  <label>To:</label>
                                </div>
                                <div className="col-md-2">
                                  <select name="month_to" className="form-control">
                                    <option value={1}>Jan
                                    </option><option value={2}>Feb
                                    </option><option value={3}>Mar
                                    </option><option value={4}>Apr
                                    </option><option value={5}>May
                                    </option><option value={6} selected>Jun
                                    </option><option value={7}>Jul
                                    </option><option value={8}>Aug
                                    </option><option value={9}>Sep
                                    </option><option value={10}>Oct
                                    </option><option value={11}>Nov
                                    </option><option value={12}>Dec
                                    </option></select> &nbsp;
                                </div>
                                <div className="col-md-2">
                                  <select name="day_to" className="form-control">
                                    <option value={1}>1
                                    </option><option value={2}>2
                                    </option><option value={3}>3
                                    </option><option value={4}>4
                                    </option><option value={5}>5
                                    </option><option value={6}>6
                                    </option><option value={7}>7
                                    </option><option value={8}>8
                                    </option><option value={9} selected>9
                                    </option><option value={10}>10
                                    </option><option value={11}>11
                                    </option><option value={12}>12
                                    </option><option value={13}>13
                                    </option><option value={14}>14
                                    </option><option value={15}>15
                                    </option><option value={16}>16
                                    </option><option value={17}>17
                                    </option><option value={18}>18
                                    </option><option value={19}>19
                                    </option><option value={20}>20
                                    </option><option value={21}>21
                                    </option><option value={22}>22
                                    </option><option value={23}>23
                                    </option><option value={24}>24
                                    </option><option value={25}>25
                                    </option><option value={26}>26
                                    </option><option value={27}>27
                                    </option><option value={28}>28
                                    </option><option value={29}>29
                                    </option><option value={30}>30
                                    </option><option value={31}>31
                                    </option></select> &nbsp;
                                </div>
                                <div className="col-md-2">
                                  <select name="year_to" className="form-control">
                                    <option value={2015}>2015
                                    </option><option value={2016}>2016
                                    </option><option value={2017}>2017
                                    </option><option value={2018}>2018
                                    </option><option value={2019}>2019
                                    </option><option value={2020}>2020
                                    </option><option value={2021}>2021
                                    </option><option value={2022}>2022
                                    </option><option value={2023} selected>2023
                                    </option></select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-4">
                              &nbsp; <input type="submit" defaultValue="Go" className="btn btn-primary ml-auto" />
                            </div>
                          </div>
                        </form> */}
                        <br /><br />
                      </div>
                    </div>
                    <br /><br />
                    <div className="table-responsive">
                      <table className="table products-table">
                        <thead>
                          <tr>
                          <th>Plan</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Date created</th>
                          </tr>
                        </thead>
                        <tbody> {
                                dataready && (
                                  userdetails.ProfitsHistory.map((obj, i) => (
                                    <tr>
                                      <td>{obj.Name}</td>
                                      <td>${obj.amt}
                                      </td>
                                      <td>{obj.Type}</td>
                                      <td>{new Date(obj.date).toLocaleString()}
                                      </td>
                                    </tr>

                                  ))

                                )
                              }
                          <tr><td colSpan={3}>&nbsp;</td>
                          </tr></tbody></table>
                      <ul className="pagination"><li className="page-item"><a className="prev page-link disabled">&lt;&lt;</a></li><li className="page-item active"><a className="page-link">1</a></li><li className="page-item"><a className="next page-link disabled">&gt;&gt;</a></li></ul>
                    </div>
                  </div></div></div>
            </div></div></div>
        {/* App footer start */}
        <div className="app-footer">© Acorn-mart</div>
        {/* App footer end */}
      </div>
      {/* Content wrapper scroll end */}
    </div>
    {/* *************
            ************ Main container end *************
        ************* */}
    {/* Page wrapper end */}
    {/* *************
        ************ Required JavaScript Files *************
    ************* */}
    {/* Required jQuery first, then Bootstrap Bundle JS */}
    {/* *************
        ************ Vendor Js Files *************
    ************* */}
    {/* Megamenu JS */}
    {/* Slimscroll JS */}
    {/* Search Filter JS */}
    {/* Apex Charts */}
    {/* Circleful Charts */}
    {/* Main Js Required */}
    {/*Start of Tawk.to Script*/}
    {/*End of Tawk.to Script*/}
  </div>
  )
}

export default ProfitsHistory