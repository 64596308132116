import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function DepositList() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [dataready, setdataready] = useState(false)


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
          setdataready(true)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/*--------------- www.hyipcustomize.com ---------*/}
      <meta name="designer" href="https://hyipcustomize.com/" />
      {/* Title */}
      <title>Acorn-mart | Dashboard</title>
      {/* *************
            ************ Common Css Files *************
        ************ */}
      {/* Bootstrap css */}
      <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
      {/* Icomoon Font Icons css */}
      <link rel="stylesheet" href="dashboard/fonts/style.css" />
      {/* Main css */}
      <link rel="stylesheet" href="dashboard/css/main.css" />
      {/* *************
            ************ Vendor Css Files *************
        ************ */}
      {/* Mega Menu */}
      <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
      {/* Search Filter JS */}
      <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
      <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
      {/* Loading wrapper start */}
      <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div>
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
                ************ Main container start *************
            ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          {/* Content wrapper scroll start */}
          <div className="content-wrapper-scroll">
            {/* Content wrapper start */}
            <div className="content-wrapper">
              {/* Row start */}
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* Card start */}
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Deposit List</div>
                    </div>
                    <div className="card-body">
                      <b>Total: $0.00</b><br /><br />
                      <div className="table-responsive">
                        <table className="table products-table">
                          <tbody><tr>
                            <td className="item">
                              <table className="table products-table">
                                <tbody><tr>
                                  <td colSpan={3} align="center"><div className="alert alert-success" role="alert"><b>BEGINNER PLAN</b></div></td>
                                </tr>
                                </tbody><thead>
                                  <tr>
                                    <th className="inheader">Plan</th>
                                    <th className="inheader" width={200}>Deposit Amount</th>
                                    <th className="inheader" width={100} nowrap><nobr>Daily Profit (%)</nobr></th>
                                  </tr></thead>
                                <tbody>
                                  <tr>
                                  <td className="item">5% in 24 Hours</td>
                                  <td className="item" align="right">$100 - $1,000</td>
                            
                                </tr>
                                </tbody></table>
                              <br />
                              <table className="table">
                                <tbody><tr>
                                  <td colSpan={4}><div className="alert alert-warning m-b-lg" role="alert"><b>No deposits for this plan</b></div></td>
                                </tr>
                                </tbody></table>
                              <br />
                            </td></tr></tbody></table>
                        <br />
                        <div className="table-responsive">
                          <table className="table products-table">
                            <tbody><tr>
                              <td className="item">
                                <table className="table products-table">
                                  <tbody><tr>
                                    <td colSpan={3} align="center"><div className="alert alert-success" role="alert"><b>AMATEUR PLAN</b></div></td>
                                  </tr>
                                  </tbody><thead>
                                    <tr>
                                      <th className="inheader">Plan</th>
                                      <th className="inheader" width={200}>Deposit Amount</th>
                                      <th className="inheader" width={100} nowrap><nobr>Daily Profit (%)</nobr></th>
                                    </tr></thead>
                                  <tbody>
                                    <tr>
                                    <td className="item">10% in 72 Hours</td>
                                    <td className="item" align="right">$1,000 - $5,000</td>
                               
                                  </tr>
                                  </tbody></table>
                                <br />
                                <table className="table">
                                  <tbody><tr>
                                    <td colSpan={4}><div className="alert alert-warning m-b-lg" role="alert"><b>No deposits for this plan</b></div></td>
                                  </tr>
                                  </tbody></table>
                                <br />
                              </td></tr></tbody></table>
                          <br />
                          <div className="table-responsive">
                            <table className="table products-table">
                              <tbody><tr>
                                <td className="item">
                                  <table className="table products-table">
                                    <tbody><tr>
                                      <td colSpan={3} align="center"><div className="alert alert-success" role="alert"><b>ADVANCE PLAN</b></div></td>
                                    </tr>
                                    </tbody><thead>
                                      <tr>
                                        <th className="inheader">Plan</th>
                                        <th className="inheader" width={200}>Deposit Amount</th>
                                        <th className="inheader" width={100} nowrap><nobr>Daily Profit (%)</nobr></th>
                                      </tr></thead>
                                    <tbody>
                                      <tr>
                                      <td className="item">15% in 5 Days</td>
                                      <td className="item" align="right">$5,000 - $10,000</td>
                                   
                                    </tr>
                                    </tbody></table>
                                  <br />
                                  <table className="table">
                                    <tbody><tr>
                                      <td colSpan={4}><div className="alert alert-warning m-b-lg" role="alert"><b>No deposits for this plan</b></div></td>
                                    </tr>
                                    </tbody></table>
                                  <br />
                                </td></tr></tbody></table>
                            <br />
                            <div className="table-responsive">
                              <table className="table products-table">
                                <tbody><tr>
                                  <td className="item">
                                    <table className="table products-table">
                                      <tbody><tr>
                                        <td colSpan={3} align="center"><div className="alert alert-success" role="alert"><b>PRO PLAN</b></div></td>
                                      </tr>
                                      </tbody><thead>
                                        <tr>
                                          <th className="inheader">Plan</th>
                                          <th className="inheader" width={200}>Deposit Amount</th>
                                          <th className="inheader" width={100} nowrap><nobr>Daily Profit (%)</nobr></th>
                                        </tr></thead>
                                      <tbody>
                                        <tr>
                                        <td className="item">25% in 10 Days</td>
                                        <td className="item" align="right">$10,000 - $100,000</td>
                                     
                                      </tr>
                                      </tbody></table>
                                    <br />
                                    <table className="table">
                                      <tbody><tr>
                                        <td colSpan={4}><div className="alert alert-warning m-b-lg" role="alert"><b>No deposits for this plan</b></div></td>
                                      </tr>
                                      </tbody></table>
                                    <br />
                                  </td></tr></tbody></table>
                              <br />
                              <div className="table-responsive">
                                <table className="table products-table">
                                  <tbody><tr>
                                    <td className="item">
                                      <table className="table products-table">
                                        <tbody><tr>
                                          <td colSpan={3} align="center"><div className="alert alert-success" role="alert"><b>EXPERT PLAN</b></div></td>
                                        </tr>
                                        </tbody><thead>
                                          {/* <tr>
                                            <th className="inheader">Plan</th>
                                            <th className="inheader" width={200}>Deposit Amount</th>
                                            <th className="inheader" width={100} nowrap><nobr>Daily Profit (%)</nobr></th>
                                          </tr> */}
                                          </thead>
                                        <tbody><tr>
                                          <td className="item">40% in 15 Days</td>
                                          <td className="item" align="right">$100,000 - $Unlimited</td>
                                       
                                        </tr>
                                        </tbody></table>
                                      <br />
                                      <table className="table">
                                        <tbody><tr>
                                          <td colSpan={4}><div className="alert alert-warning m-b-lg" role="alert"><b>No deposits for this plan</b></div></td>
                                        </tr>
                                        </tbody></table>
                                      <br />
                                    </td></tr></tbody></table>
                                <br />
                              </div>
                           
                            </div></div></div>
                      </div></div></div></div>
                {/* App footer start */}
                <div className="app-footer">© Acorn-mart</div>
                {/* App footer end */}
              </div>
              {/* Content wrapper scroll end */}
            </div>
            {/* *************
                ************ Main container end *************
            ************* */}
          </div>
          {/* Page wrapper end */}
          {/* *************
            ************ Required JavaScript Files *************
        ************* */}
          {/* Required jQuery first, then Bootstrap Bundle JS */}
          {/* *************
            ************ Vendor Js Files *************
        ************* */}
          {/* Megamenu JS */}
          {/* Slimscroll JS */}
          {/* Search Filter JS */}
          {/* Apex Charts */}
          {/* Circleful Charts */}
          {/* Main Js Required */}
          {/*Start of Tawk.to Script*/}
          {/*End of Tawk.to Script*/}
        </div></div></div>
  )
}

export default DepositList